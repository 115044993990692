import React, { useContext, useEffect } from "react";
import "./IntroPage.scss";
import { useFetch } from "react-async";
import { GlobalStateContext } from "AppRouter";
import { HeaderTheme } from "components/molecules/Header/Header";
import { FullscreenStage } from "components/molecules/FullscreenStage/FullscreenStage";
import { LoadingSpinner } from "components/atoms/LoadingSpinner/LoadingSpinner";

function IntroPage({ page, pageId, match }) {
  const { setHeaderTheme, language } = useContext(GlobalStateContext);

  useEffect(() => {
    setHeaderTheme(HeaderTheme.LIGHT);
  }, []);

  // AJAX call
  const jsonUrl = process.env.REACT_APP_LOKAL_FETCH
      ? process.env.PUBLIC_URL + `/${language}/${pageId}.json`
      : `${process.env.REACT_APP_CONTAO_URL}${pageId}?language=${language}`;

  const headers = { Accept: "application/json" };
  const { data, error, isLoading } = useFetch(jsonUrl, {
    headers,
  });

  if (isLoading) return <LoadingSpinner />;
  if (error) return `Something went wrong: ${error.message}`;

  if (data) {
    // TODO: this is hard-coded to the first Object
    const currentArticleId = Object.keys(data)[0];

    return (
      <>
        <FullscreenStage
          {...data[currentArticleId].content[0]}
          page={page}
          match={match}
        />
      </>
    );
  }
}

export { IntroPage };
