import React, { useRef, useState, useEffect, useContext } from "react";
import "./Media.scss";

import { GlobalStateContext } from "AppRouter";

import { IconPlay } from "components/atoms/IconPlay";
import { IconPause } from "components/atoms/IconPause";

import Observer from "@researchgate/react-intersection-observer";

function Media({
  mediaPath,
  background = false,
  className,
  media,
  mediaDisclaimer = ""
}) {
  const [videoState, setVideoState] = useState("paused");
  const { currentVideoPlaying, setCurrentVideoPlaying } = useContext(
    GlobalStateContext
  );

  const videoEl = useRef(null);

  useEffect(() => {
    if (media !== "video") return;
    const video = videoEl.current;
    if (!videoEl.current) return;

    video.addEventListener("pause", onPaused);
    video.addEventListener("ended", onPaused);
    video.addEventListener("playing", e => onPlaying(e));

    function onPlaying(e) {
      setVideoState("playing");
      setCurrentVideoPlaying(e.target);
    }

    function onPaused() {
      setVideoState("paused");
      setCurrentVideoPlaying(null);
    }

    return () => {
      video.removeEventListener("pause", onPaused);
      video.removeEventListener("ended", onPaused);
      video.removeEventListener("playing", e => onPlaying(e));
    };
  }, [media, setCurrentVideoPlaying]);

  function togglePlay() {
    if (!videoEl.current) return;

    if (videoEl.current.paused) {
      if (currentVideoPlaying) {
        currentVideoPlaying.pause();
      }
      videoEl.current.muted = true;
      videoEl.current.play();
    } else {
      videoEl.current.pause();
    }
  }

  function handleIntersection(event) {
    if (event.isIntersecting) {
      // console.log("%o is playing", videoEl);
      // console.log(currentVideoPlaying);
      if (currentVideoPlaying) {
        currentVideoPlaying.pause();
      }
      videoEl.current.play();
    } else {
      // console.log("%o is paused", videoEl);
      videoEl.current.pause();
    }
  }

  // °°°°°°°°°°°°°°°°°°°°°°°°°
  // Video
  // .........................
  if (media === "video") {
    const observerOptions = {
      onChange: handleIntersection,
      root: "body",
      rootMargin: "0% 0% -25%"
    };

    return (
      <Observer {...observerOptions}>
        <figure
          className={`media-container  ${
            background ? "media-container--background-video" : ""
          }`}
        >
          <div className="media-container__video-container">
            {!background && (
              <div
                className={`media-container__controls ${
                  videoState === "paused"
                    ? "media-container__controls--paused"
                    : ""
                }`}
                onClick={togglePlay}
              >
                {videoState === "paused" ? (
                  <IconPlay variant="default" width="100" height="100" />
                ) : (
                  <IconPause variant="default" width="40" height="40" />
                )}
              </div>
            )}

            <video
              ref={videoEl}
              src={`${decodeURIComponent(mediaPath)}`}
              className="media-container__video"
              muted={true}
              autoPlay={background}
              loop={true}
            />
          </div>
          {mediaDisclaimer && (
            <div className="media-container__caption-container">
              <figcaption
                className={`media-container__disclaimer  ${
                  background
                    ? "media-container__disclaimer--background-video"
                    : ""
                }`}
              >
                {mediaDisclaimer}
              </figcaption>
            </div>
          )}
        </figure>
      </Observer>
    );
  }

  // °°°°°°°°°°°°°°°°°°°°°°°°°
  // Background Image
  // .........................

  if (background) {
    return (
      <div
        style={{
          backgroundImage: `url(${decodeURIComponent(mediaPath)})`
        }}
        className={className}
      />
    );
  }

  // °°°°°°°°°°°°°°°°°°°°°°°°°
  // Normal Image
  // .........................
  return (
    <div className="figure-container">
      <figure className={`media-container ${className}`}>
        <img
          src={`${decodeURIComponent(mediaPath)}`}
          className="media-container__image"
          alt="foo"
        />
        {mediaDisclaimer && (
          <div className="media-container__caption-container">
            <figcaption className="media-container__disclaimer">
              {mediaDisclaimer}
            </figcaption>
          </div>
        )}
      </figure>
    </div>
  );
}

export { Media };
